<template>
  <div class="dizme_tm_all_wrap" data-magic-cursor="show">
    <MobileHeaderVue />
    <HeaderVue />
    <HomeComponent />
    <AboutComponent />
    <ContactComponent />
    <CopyrightComponent />
    <ScrollTopVue />
  </div>
</template>

<script>
import HeaderVue from "@/components/layout/Header.vue";
import MobileHeaderVue from "@/components/layout/MobileHeader.vue";
import ScrollTopVue from "@/components/layout/ScrollTop.vue";
import AboutComponent from "../components/AboutComponent.vue";
import ContactComponent from "../components/ContactComponent.vue";
import CopyrightComponent from "../components/CopyrightComponent.vue";
import HomeComponent from "../components/HomeComponent.vue";

export default {
  name: "IndexView",
  components: {
    HomeComponent,
    AboutComponent,
    CopyrightComponent,
    ContactComponent,
    HeaderVue,
    MobileHeaderVue,
    ScrollTopVue,
  },
  mounted() {
  const scrollHandler = () => {
  };
  window.addEventListener("scroll", scrollHandler);
}
};
</script>
