<template>
  <div class="dizme_tm_section" id="contact">
    <div class="dizme_tm_contact">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>Contact</span>
        </div>
        <div class="contact_inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <ul>
              <li>
                <h3>Message Stephan Westermayer</h3>
                <br />
                <p>
                  Please fill out the form below to share your ideas for
                  investment plans and learn more about Blue Palm's journey. I
                  look forward to hearing from you and collaborating for our
                  shared success.
                </p>
                <br />
                <img src="img/brushes/contact/contact-vector-01.png" alt="" />
              </li>
            </ul>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <div class="fields">
              <form action="#" @submit.prevent="handleSubmit">
                <div class="input_list">
                  <ul>
                    <li>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Your Name"
                        required
                        v-model="credentials.name"
                      />
                    </li>
                    <li>
                      <input
                        id="email"
                        name="email"
                        type="text"
                        placeholder="Your Email"
                        required
                        v-model="credentials.email"
                      />
                    </li>
                    <li>
                      <input
                        id="phone"
                        name="phone"
                        type="number"
                        placeholder="Your Phone"
                        v-model="credentials.phone"
                      />
                    </li>
                    <li>
                      <input
                        id="subject"
                        name="subject"
                        type="text"
                        placeholder="Subject"
                        v-model="credentials.subject"
                      />
                    </li>
                  </ul>
                </div>
                <div class="message_area">
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Write your message here"
                    v-model="credentials.message"
                  ></textarea>
                </div>
                <div class="file_area">
                  <label for="file" class="file_upload_button">
                    <span>Choose File</span>
                  </label>
                  <input
                    id="file"
                    name="file"
                    type="file"
                    style="display: none"
                    @input="handleFile"
                  />
                  <p class="file_upload_info">
                    {{ selectedFile || "Please select a file" }}
                    <span style="margin-left: 5px; font-size: 13px"
                      >Max. Size: 4MB</span
                    >
                  </p>
                </div>
                <div class="button">
                  <div class="dizme_tm_button">
                    <button type="submit" :disabled="submitting">
                      <span v-if="!submitting">Submit Now</span>
                      <span v-else>Sending...</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="brush_2 wow fadeInRight" data-wow-duration="1s">
            <img src="img/brushes/contact/4.png" alt="" width="300" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { ref } from "vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "ContactComponent",
  setup() {
    const file = ref(null);
    const credentials = reactive({
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
    const selectedFile = ref("");
    const submitting = ref(false);

    const handleFile = (event) => {
  const selected = event.target.files[0];
  if (selected) {
    const fileSize = selected.size / 1024 / 1024; 
    if (fileSize > 4) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a file smaller than 4MB.",
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      event.target.value = null; 
    } else {
      file.value = selected;
      selectedFile.value = selected.name;
    }
  }
};

    const handleSubmit = async () => {
      if (
        !credentials.name ||
        !credentials.email ||
        !credentials.phone ||
        !credentials.subject ||
        !credentials.message ||
        !file.value
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please fill out all fields.",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        return;
      }

      submitting.value = true;

      let formData = new FormData();
      formData.append("file", file.value);

      Object.keys(credentials).forEach((key) => {
        formData.append(key, credentials[key]);
      });

      try {
        const response = await axios.post(
          "https://db.xenovo.com.tr/api/sw-contact-mail",
          formData
        );
        console.log(response.data);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Message sent successfully!",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        Object.keys(credentials).forEach((credential) => {
          credentials[credential] = "";
        });
        file.value = null;
        selectedFile.value = "";
      } catch (error) {
        console.error("Error submitting the form:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred. Please try again later.",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } finally {
        submitting.value = false;
      }
    };

    return {
      file,
      credentials,
      selectedFile,
      handleFile,
      handleSubmit,
      submitting,
    };
  },
};
</script>
<style>
.swal2-icon {
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

div:where(.swal2-icon).swal2-success .swal2-success-ring {
  left: -0.8em;
}

div:where(.swal2-icon).swal2-success
  [class^="swal2-success-line"][class$="long"] {
  right: 0.9em;
}

div:where(.swal2-icon).swal2-success
  [class^="swal2-success-line"][class$="tip"] {
  left: 0.4em;

}
@media (max-width: 1800px) {
  .file_upload_button {
    width: 35% !important;
  }
}
@media (max-width: 500px) {
  .file_upload_button {
    width: 42% !important;
  }
}
</style>
