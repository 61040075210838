<template>
  <div class="dizme_tm_section" id="home">
    <div class="dizme_tm_hero">
      <div class="background" :data-img-url="`/img/slider/${dark ? 2 : 5}.jpg`"></div>
      <div class="container">
        <div class="content">
          <div class="social-links">
            <a class="social-link linkedin anim_moveBottom" href="https://www.linkedin.com/in/stephan-westermayer/" target="_blank">
              <img src="/img/logo/linkedin.png" alt="LinkedIn">
            </a>
          </div>
          <div class="details">
            <div class="hello">
              <!-- <h3 class="orangeText">A leader who invests in vision:</h3> -->
            </div>
            <div class="name">
              <h3>Stephan Westermayer</h3>
            </div>
            <div class="job">
              <p>
                <span class="purpleText">Blue Palm Group CEO</span>
              </p>
            </div>
            <div class="text">
              <p>
                I am optimistic about the future and eager to spearhead a paradigm shift in the real estate industry. I am committed to turning my vision into reality, even in the face of challenges.
              </p>
            </div>
            <div class="button">
              <div class="dizme_tm_button">
                <a class="custom-button" href="#vision"><span>Vision</span></a>
              </div>
            </div>
          </div>
          <div class="avatar">
            <div class="image">
              <img src="/img/slider/stephan.png" alt="" />
              <span class="skills illustrator anim_moveBottom"
                ><img src="/img/slider/bp-icon.png" alt=""
              /></span>
            </div>
          </div>
        </div>
      </div>
      <div class="dizme_tm_down">
        <a class="anchor" href="#about" v-html="dark ? mouseDark : mouse"> </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeComponent",
};
</script>
<style>
.social-links {
  position: absolute;
  bottom: 230px; 
  right: 170px; 
  z-index: 1;
}

.social-link {
  display: inline-block;
  margin-left: 10px;
}

.social-link img {
  width: 43px; 
  height: auto;
}
@media (min-width: 1900px) {
  .social-links {
    bottom: 230px; 
    right: 100px; 
  }

  .social-link img {
    width: 60px; 
  }
}
@media (max-width: 1024px) {
  .social-links {
    position: absolute;
    bottom: 30px; 
    right: 170px; 
    z-index: 1;
  }
  
  .social-link {
    display: inline-block;
    margin-left: 10px;
  }
  
  .social-link img {
    width: 43px; 
    height: auto;
  }
}
@media (max-width: 768px) {
  .social-links {
    bottom: 70px; 
    right: 80px; 
  }

  .social-link img {
    width: 30px; 
  }
}
@media (max-width: 480px) {
  .social-links {
    bottom: 30px; 
    right: 50px; 
  }

  .social-link img {
    width: 25px; 
  }
}

</style>