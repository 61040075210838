<template>
  <div class="dizme_tm_section">
    <div class="dizme_tm_copyright">
      <div class="container">
        <div class="inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <a href="#">
              <img
                :src="`img/logo/${dark ? 'dark' : 'stephan'}.png`"
                alt=""
                width="200"
              />
            </a>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <p style="margin-bottom: 10px; margin-top: 0; color: #554872">
              Pages
            </p>
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#vision">Vision</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <p style="margin-bottom: 10px; margin-top: 0; color: #554872">
              Links
            </p>
            <ul>
              <li style="margin-bottom: 5px">
                <a href="https://bluepalm.group/" target="_blank"
                  >Blue Palm Group</a
                >
              </li>
              <li style="margin-bottom: 5px">
                <a href="https://bluepalm.rentals/" target="_blank"
                  >Blue Palm Hosting Services</a
                >
              </li>
            </ul>
          </div>
          <!-- <div class="right wow fadeInRight" data-wow-duration="1s">
            <p style="margin-bottom: 10px; margin-top: 0; color: #554872">
              Social Media
            </p>
            <ul>
              <li style="margin-bottom: 5px">
                <a
                  href="https://www.linkedin.com/in/stephan-westermayer/"
                  target="_blank"
                >
                  <img class="social-icon" src="img/logo/linkedin.png" alt="" />
                </a>
              </li>
            </ul>
          </div> -->
          <div class="social-links">
            <a class="social-link linkedin" href="https://www.linkedin.com/in/stephan-westermayer/" target="_blank">
              <img src="/img/logo/linkedin.png" alt="LinkedIn">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopyrightComponent",
  components: {},
};
</script>
<style scoped>
.right ul li {
  margin-top: 0;
}
.social-icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.social-links {
  position: absolute;
  bottom: 8px; 
  right: -100px; 
  z-index: 1;
}

.social-link {
  display: inline-block;
  margin-left: 10px;
}

.social-link img {
  width: 30px; 
  height: auto;
}
@media screen and (max-width: 1040px) {
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .right {
    text-align: center;
  }

  .right ul {
    padding-left: 0;
  }
  .right p {
    padding-top: 20px;
  }
}
</style>
