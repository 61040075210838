<template>
  <div class="dizme_tm_section" id="vision">
    <div class="dizme_tm_about">
      <div class="container">
        <div class="wrapper">
          <div class="left">
            <div class="image">
              <img :src="`/img/about/${dark ? 2 : 4}.png`" alt="" />
            </div>
          </div>
          <div class="right">
            <div class="title wow fadeInUp" data-wow-duration="1s">
              <h3>Financing and useful design</h3>
            </div>
            <div class="text wow fadeInUp" data-wow-duration="1s">
              <p>
                At the beginning, I had to explain to banks what I was doing,
                but they didn't understand it and the financing was laborious
                and difficult.
              </p>
              <br />
              <p>
                Today, just a few years later, real estate developers are
                recruiting us for their projects because our lease agreements
                enable them to finance the entire development.
              </p>
              <br />
              <p>
                In the future, we will design the projects completely by
                ourselves. We will develop the ideas and invite selected
                developers to build properties according to our residential
                space specifications. This last step will complete the
                transformation from a fınance petitioner to a financially
                independent real estate residential space designer.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="/img/brushes/about/4.png" alt="" width="300" />
      </div>
      <div class="brush_2 wow fadeInLeft" data-wow-duration="1s">
        <img src="/img/brushes/about/3.png" alt="" width="300" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutComponent",
  props: {
    dark: { type: Boolean },
  },
};
</script>
<style>
@media (max-width: 1700px) {
  .dizme_tm_about .brush_1 {
    width: 250px;
  }
  .dizme_tm_about .brush_2 {
    width: 200px;
  }
}
</style>
